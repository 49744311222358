import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrcaAPI } from '../../ApiServices/OrcaAPI';
import calculatePrioritizationScore from '../../utils/PriorityScore';
import React from 'react';

const initialState = {
  tableData: [],
};

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setRequestsData: (state, action) => {
      state.tableData = action.payload
    },
    addRequest: (state, action) => {
      action.payload.cardnumber =  <a href={'https://lilly-jira.atlassian.net/browse/' + action.payload.cardnumber} style={{ color: 'blue' }} target='_blank' rel='noreferrer'>{action.payload.cardnumber}</a>
      let update = false
      let tableData = state.tableData.map(row => {
        if (row.id === action.payload.id) {
          update = true
          return action.payload;
        }
        return row
      })
      if (!update) {
        state.tableData = [action.payload, ...state.tableData ];
      } else {
        state.tableData = [...tableData]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestsData.pending, (state) => {
        state.tableData = [];
        state.loading = true;
      })
      .addCase(getRequestsData.fulfilled, (state, action) => {
        state.loading = false;
        let incomingRows = action.payload.map(row => {
          return {
            ...row,
            sales_force_team: row.sales_force_team?.split(';'),
            insight_graph_applicable_for_sleeve: row.insight_graph_applicable_for_sleeve?.split(';'),
            cardnumber: <a href={'https://lilly-jira.atlassian.net/browse/' + row.cardnumber} style={{ color: 'blue' }} target='_blank' rel='noreferrer'>{row.cardnumber}</a>,
            prioritization_score: calculatePrioritizationScore(row)
          }
        });
        state.tableData = incomingRows.sort((a, b) => { return Number(b.id) - Number(a.id)})
      })
      .addCase(getRequestsData.rejected, (state) => {
        state.loading = false;
        state.tableData = [];
      }).addCase(deleteRequestData.pending, (state) => {
        state.loading = true;
      }).addCase(deleteRequestData.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = state.tableData.filter((row) => { return row.id !== action.payload.id; });
      }).addCase(deleteRequestData.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const getRequestsData = createAsyncThunk('requests/getRequestsData', async () => {
  const response = await OrcaAPI({ uri: "/submit_request", method: "GET" });
  return response.data;
})

export const deleteRequestData = createAsyncThunk('requests/deleteRequestsData', async (data) => {
  data = { ...data, action: 'delete' };
  const response = await OrcaAPI({ uri: "/consolidated_sugg_lib", method: "PUT", body: data });
  if (response.statusCode === 200) {
    return data;
  }
  return null;
});

export const { generateRequestsData, setRequestsData, addRequest } = requestsSlice.actions;
export const selectRequestsData = (state) => state.requests.tableData;
export const getLoadingState = (state) => state.requests.loading;

export default requestsSlice.reducer;
