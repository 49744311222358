import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrcaAPI } from '../../ApiServices/OrcaAPI';
import React from 'react';

const initialState = {
  tableData: [],
};

export const mlSlice = createSlice({
  name: 'ml',
  initialState,
  reducers: {
    setMlData: (state, action) => {
      state.tableData = action.payload
    },
    addMl: (state, action) => {
      let update = false
      let tableData = state.tableData.map(row => {
        if (row.id === action.payload.id) {
          update = true
          return action.payload;
        }
        return row
      })
      if (!update) {
        state.tableData = [action.payload, ...state.tableData ];
      } else {
        state.tableData = [...tableData]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMlData.pending, (state) => {
        state.tableData = [];
        state.loading = true;
      })
      .addCase(getMlData.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
        let incomingRows = action.payload.map(row => {
          return {
            ...row
          };
        });
        state.tableData = incomingRows.sort((a, b) => { return Number(b.id) - Number(a.id)});
      }})
      .addCase(getMlData.rejected, (state) => {
        state.loading = false;
        state.tableData = [];
      });
  }
});

export const getMlData = createAsyncThunk('ml/getMlData', async () => {
  const response = await OrcaAPI({ uri: "/metrics_library", method: "GET" });
  return response.data;
})

export const { generateMlData, setMlData, addMl } = mlSlice.actions;
export const selectMlData = (state) => state.ml.tableData;
export const getLoadingState = (state) => state.ml.loading;

export default mlSlice;
