import React from "react";
import LdsCardComponent from "../../Components/common/LdsCard";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

export default function NotFoundPage() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleLogin = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <LdsCardComponent
        className="py-4 px-2 m-0 shadow-lg p-3 mt-2 bg-white rounded"
        bodyStyle={{ height: "85vh" }}
      >
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col text-center">
            <h2>Not Found</h2>
            {!activeAccount ? (
              <button className="btn" onClick={handleLogin}>
                Authorize
              </button>
            ) : null}
          </div>
        </div>
      </LdsCardComponent>
    </>
  );
}
