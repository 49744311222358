import React from "react";
import Contact from "../Pages/Contact/Contact";
import NotFoundPage from "../Pages/SharedPages/NotFound";

export const unprotectedRoutes = [
    {
        path: "/",
        element: <NotFoundPage />,
        permissions: []
    },
    {
        path: "*",
        element: <NotFoundPage />,
        permissions: []
    },
    {
        path: "/contactus",
        element: <Contact />,
        permissions: []
    }
]