const calculatePrioritizationScore = (row) => {
  const priorityToInt = (bv_priority) => {
    switch ((bv_priority || '').toUpperCase()) {
      case 'HIGH': return 3;
      case 'MED':
      case 'MEDIUM': return 2;
      case 'LOW': return 1;
      default: return 0;
    }
  };

  const requestCatToDecimal = (bv_category) => {
    switch ((bv_category || '').toUpperCase()) {
      case 'STRATEGIC':
      case 'STRATEGIC CHANGE':
      case 'STRATEGIC_CHANGE': return 0.5;
      case 'FIELD EXPERIENCE':
      case 'FIELD_EXPERIENCE': return 0.3;
      case 'FEEDBACK':
      case 'BACKEND_INFRA': return 0.2;
      case 'BACKEND INFRA': return 0.2;
      default: return 0.2;
    }
  };
  
  const { bv_category, bv_priority, bv_urgency, bv_impact } = row;

  const brandNum = priorityToInt(bv_priority);
  const requestNum = priorityToInt(bv_urgency);
  const confidenceNum = priorityToInt(bv_impact);
  const requestCatNum = requestCatToDecimal(bv_category);

  const weights = ((0.3 * brandNum) + (0.4 * requestNum) + (0.3 * confidenceNum)) * requestCatNum;
  const finalScore = (weights / 1.5) * 10;
  return Math.round(finalScore * 100) / 100;
}

export default calculatePrioritizationScore