import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { getLoadingState, selectServiceCatalogData, getServiceCatalogData } from '../../redux/slices/serviceCatalogSlice'; 
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';

const ServiceCatalog = () => {
  const data = useSelector(selectServiceCatalogData);
  const loading = useSelector(getLoadingState);

  useEffect(() => {
    store.dispatch(getServiceCatalogData());
  } , []);

  return (
  <OrcaTable loading={loading} showFilters rows={data} title='Service Catalog (Effort Estimation - FTE Weeks)' displayKey={{'enhancement_request':{'display':true, 'shorten':false}, 'granular_request':{'display':true, 'shorten':false}, 'effort_po_sfo':{'display':true }, 'effort_po_bia':{'display':true }, 'effort_dtt_sfo':{'display':true }, 'effort_o2':{'display':true }, 'effort_oppmodels_bia':{'display':true,  }}}/>
  );
};
export default ServiceCatalog;
