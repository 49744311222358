import React from "react";
import { LdsCard } from "@elilillyco/ux-lds-react";
import { useMsal } from "@azure/msal-react";

export default function NotAuthorizedPage( { path }) {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  return (
    <>
      <LdsCard>
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col text-center">
            <h2>User: { activeAccount.username } is not authorized to access path:{ path }</h2>
          </div>
        </div>
      </LdsCard>
    </>
  );
}
