import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/slices/userSlice";

export const OrcaUser = ({role}) => {
  let [userRole, setUserRole] = useState();
  useEffect(() => {
    setUserRole(role);
  }, [role]);
  const { accounts } = useMsal();
  const userData = useSelector(selectUserData);

  return (
    <div className="user-widget" style={{borderLeft: '3px solid lightgrey', paddingLeft: '15px'}}>
      <p className="mt-3">
        <b>
          {accounts && accounts.length
            ? accounts[0].name.split("-")[0].trim()
            : "Login"}
          <br />
          {userData.role || "???"}
        </b>
      </p>
    </div>
  );
};