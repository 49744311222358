import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { LdsButton, LdsScrollingCheckboxGroup, LdsValidationError } from '@elilillyco/ux-lds-react';
import { OrcaFormLabelOverrides, OrcaFormTooltipOverrides } from "./OrcaFormLabelOverrides";
import { OrcaFormOptions } from './OrcaFormOptions';

const OrcaScrollingCB = ({ handleChange, options, name, label, disabled, required, ...rest}) => {
    const [isAll, setIsAll] = useState(false);
    const { touched, errors, values, setFieldValue } = useFormikContext();
    let displayLabel = OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || '';
    if (required) {
        displayLabel = <>{displayLabel} <span title='Required' style={{cursor: 'help', color: 'red'}} className="required-label">*</span></>;
    }
    if (!values[name]) {
        values[name] = [];
        setFieldValue(name, []);
    }
    if (typeof values[name] == 'string' || values[name] instanceof String) {
        values[name] = values[name].split(';')
    }
    // eslint-disable-next-line no-unused-vars
    const isChecked = (value) => {
        return values[name].includes(value);
    };
    options = options || OrcaFormOptions[name] || [{ value: "", label: "" }];
    const scrollingCBOptions = options?.map((option) => { return { ...option, 'disabled':disabled }}) || []
    const setOptionValues = (newValues) => {
        touched[name] = true;
        setFieldValue(name, newValues);
    };
    return (<div style={{display: 'flex', flexDirection: 'column'}}>
        <LdsScrollingCheckboxGroup
        label={displayLabel}
        labelId={OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || ''}
        options={scrollingCBOptions}
        values={values[name]}
        required={rest?.required || false}
        error={errors[name] && touched[name]}
        setValues={setOptionValues}
        {...rest}
        />
        { errors[name] && touched[name] && <LdsValidationError id={"err-" + name}>{errors[name]}</LdsValidationError> }
    </div>);
};

export default OrcaScrollingCB