import React, { useRef, useState, useEffect } from "react";
import QuillEditor from "./QuillEditor";
import { LdsTextField, LdsTextarea, LdsValidationError, LdsButton } from "@elilillyco/ux-lds-react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { selectServiceCatalogData } from "../../redux/slices/serviceCatalogSlice";
import calculatePrioritizationScore from "../../utils/PriorityScore";
import { OrcaFormLabelOverrides, OrcaFormTooltipOverrides } from "./OrcaFormLabelOverrides";
import Quill from 'quill';
import "quill/dist/quill.snow.css";

export const QuillTextArea = ({ name, label, ...rest }) => {
    const { values, handleChange, touched, setFieldValue } = useFormikContext();
    const Delta = Quill.import('delta');
    const quillRef = useRef();

    const handleTextChange = (content) => {
        values[`${name}_delta_obj`] = quillRef.current.getContents();
    };

    let value = values[name];
    if (values[`${name}_delta_obj`] != null) {
        value = values[`${name}_delta_obj`];
    }

    return (
        <div>
            <label id={'label-' + name || label || ''}>{OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || ''}</label>
            <QuillEditor
                ref={quillRef}
                readOnly={rest.disabled}
                onTextChange={handleTextChange}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
}

export const OrcaField = ({ name, label, required, ...rest }) => {
    const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let displayLabel = OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || '';
    if (required) {
        displayLabel = <>{displayLabel} <span title='Required' style={{cursor: 'help', color: 'red'}} className="required-label">*</span></>;
    }
    if (rest?.hidden === true) {
        return <input type="hidden" name={name} value={values[name]} onChange={handleChange} />
    }
    return (<div style={{display: 'flex', flexDirection: 'column'}}>
        <LdsTextField
        id={name}
        name={name}
        label={displayLabel}
        value={values[name]}
        defaultValue={values[name]}
        onChange={handleChange}
        style={{color: 'inherit'}}
        required={rest?.required || false}
        error={errors[name] && touched[name]}
        {...rest}
        />
        { errors[name] && touched[name] && <LdsValidationError id={"err-" + name}>{errors[name]}</LdsValidationError> }
    </div>);
}

export const OrcaTextArea = ({ name, label, required, ...rest }) => {
    const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let displayLabel = OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || '';
    if (required) {
        displayLabel = <>{displayLabel} <span title='Required' style={{cursor: 'help', color: 'red'}} className="required-label">*</span></>;
    }
    return (<div style={{display: 'flex', flexDirection: 'column'}}>
        <LdsTextarea
        id={name}
        name={name}
        label={displayLabel}
        value={values[name]}
        defaultValue={values[name]}
        onChange={handleChange}
        multiline
        style={{color: 'inherit'}}
        rows={4}
        required={rest?.required || false}
        error={errors[name] && touched[name]}
        {...rest}
        />
        { errors[name] && touched[name] && <LdsValidationError id={"err-" + name}>{errors[name]}</LdsValidationError> }
    </div>);
}

export const OrcaEffortField = ({ name, ...rest }) => {
    const { values: {effort_enhancement_request, effort_granular_request}, handleChange, errors, touched, setFieldValue } = useFormikContext();
    let serviceCatalogData = useSelector(selectServiceCatalogData);
    useEffect(() => {
        let selected = serviceCatalogData.filter((option) => {
            return option.enhancement_request.toLowerCase() === effort_enhancement_request.toLowerCase() && option.granular_request.toLowerCase() === effort_granular_request.toLowerCase();
        });
        if (selected.length == 1) {
            setFieldValue(name, selected[0][name] || '')
        }
    }, [effort_enhancement_request, effort_granular_request])

    return (
        <OrcaField name={name} type="number" {...rest} />
    );
}

export const OrcaPriorityScore = ({ name, ...rest }) => {
    const { values: {bv_category, bv_priority, bv_urgency, bv_impact}, handleChange, errors, touched, setFieldValue } = useFormikContext();
    useEffect(() => {
        const bv_state = {bv_category, bv_priority, bv_urgency, bv_impact};
        const score = calculatePrioritizationScore(bv_state);
        setFieldValue(name, score);
    }, [bv_category, bv_priority, bv_urgency, bv_impact])

    return (
        <OrcaField disabled name={name} type="number" {...rest} />
    );
}