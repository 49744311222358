import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sprintDates: [],
};

export const sprintDatesSlice = createSlice({
  name: 'sprintDates',
  initialState,
  reducers: {
    getSprintDatesData: (state, action) => {
        state.sprintDatesData = 
[{"sprint":"ES3","start_date":"01-Apr-2024","mid_quarter_date":"13-May-2024","end_quarter_date":"08-Jul-2024"},
 {"sprint":"ES4","start_date":"01-Jul-2024","mid_quarter_date":"12-Aug-2024","end_quarter_date":"07-Oct-2024"},
 {"sprint":"AS4A","start_date":"08-Jul-2024","mid_quarter_date":"N/A","end_quarter_date":"05-Aug-2024"},
 {"sprint":"AS4B","start_date":"05-Aug-2024","mid_quarter_date":"N/A","end_quarter_date":"02-Sep-2024"},
 {"sprint":"AS4C","start_date":"02-Sep-2024","mid_quarter_date":"N/A","end_quarter_date":"07-Oct-2024"},
 {"sprint":"ES5","start_date":"07-Oct-2024","mid_quarter_date":"11-Nov-2024","end_quarter_date":"06-Jan-2025"},
 {"sprint":"AS5A","start_date":"07-Oct-2024","mid_quarter_date":"N/A","end_quarter_date":"04-Nov-2024"},
 {"sprint":"AS5B","start_date":"04-Nov-2024","mid_quarter_date":"N/A","end_quarter_date":"02-Dec-2024"},
 {"sprint":"AS5C","start_date":"02-Dec-2024","mid_quarter_date":"N/A","end_quarter_date":"06-Jan-2025"}]
    },
  },
});

export const { getSprintDatesData } = sprintDatesSlice.actions;
export const selectSprintDatesData = (state) => state.sprintDates.sprintDatesData;

export default sprintDatesSlice.reducer;
