import React from 'react';
import { LdsButton} from "@elilillyco/ux-lds-react";
import { useSelector, useDispatch } from "react-redux";
import { getSprintDatesData, selectSprintDatesData } from '../../redux/slices/sprintDatesSlice'; 
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';

store.dispatch(getSprintDatesData());

const SprintDates = () => {
  const data = useSelector(selectSprintDatesData);

  return (
    <>
    <OrcaTable rows={data} title="Sprint Dates" displayKey={{'sprint':{'display': true}, 'start_date':{'display': true}, 'mid_quarter_date':{'display':true}, 'end_quarter_date':{'display':true}}} />
    </>
  );
};

export default SprintDates;
