// Object to map AD roles to application roles
const Roles = {
  "orca_admin": "Admin",
  "orca_requester": "Requester",
  "orca_service_cataloger": "Service Cataloger",
  "orca_sprint_manager": "Sprint Manager",
  "orca_viewer": "Viewer",

};

export default Roles;
