import { createSlice } from "@reduxjs/toolkit";

let userInitialState = {
  user: null,
  role: null,
  username: null,
  groups: null,
  token: null,
  name: null,
};

export const userSlice = createSlice({
  name: "user",
  // namebu: "bu",
  initialState: {
    data: userInitialState,
    selectBu: null,
    selectMarket: null,
    selectProduct: null,
    approveTable: null,

    marketData: [],
    productData: [],
  },
  reducers: {
    addUser: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      let dataNew = action.payload;
      state.data = dataNew;
    },

    selectBu: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      let selectBuValue = action.payload;
      if (selectBuValue && selectBuValue.length > 0) {
        selectBuValue = selectBuValue.map((bu, index) => {
          bu = {
            id: index + 1,
            label: bu.bu,
            value: bu.bu,
          };
          return bu;
        });

        selectBuValue = [
          ...new Map(
            selectBuValue.map((item) => [item["label"], item])
          ).values(),
        ];
        let ObjSelect = { id: 0, label: "Select BU", value: "Select BU" };
        selectBuValue.unshift(ObjSelect);

        state.selectBu = null;
        state.selectBu = selectBuValue;
      }
    },

    setMarketData: (state, action) => {
      // console.log(action.payload, "actionpaylads");
      console.log(getMarketDataByBu(action.payload));
      state.marketData = null;

      state.productData = null;
      const newMarketData = getMarketDataByBu(action.payload);

      state.marketData = JSON.parse(JSON.stringify(newMarketData));
    },

    setProductData: (state, action) => {
      state.productData = null;
      let newProdData = getProductDataByMarket(action.payload);
      state.productData = JSON.parse(JSON.stringify(newProdData));
    },

    resetValue: (state, action) => {
      state.selectBu = null;
      state.marketData = null;
      state.productData = null;
    },

  },
});

const getMarketDataByBu = (payload) => {
  let marketDataByBu = payload.data.filter(
    (product) => product.bu === payload.buValue
  );
  marketDataByBu = marketDataByBu.map((data, index) => ({
    id: data.thcl_prod_id,
    value: data.thcl_prod_nm,
    label: data.thcl_prod_nm,
  }));
  marketDataByBu = [
    ...new Map(marketDataByBu.map((item) => [item["label"], item])).values(),
  ];
  let ObjSelect = { id: 0, label: "Select Market", value: "Select Market" };

  marketDataByBu.unshift(ObjSelect);
  return marketDataByBu;
};

const getProductDataByMarket = (payload) => {
  let productDataByMarket = payload.data.filter(
    (product) => product.thcl_prod_nm === payload.input_product_name
  );

  productDataByMarket = productDataByMarket.map((data, index) => ({
    id: data.mktp_prod_id,
    value: data.mktp_prod_nm,
    label: data.mktp_prod_nm,
  }));
  productDataByMarket = [
    ...new Map(
      productDataByMarket.map((item) => [item["label"], item])
    ).values(),
  ];

  let ObjSelect = { id: 0, label: "Select Product", value: "Select Product" };

  productDataByMarket.unshift(ObjSelect);
  return productDataByMarket;
};

// Action creators are generated for each case reducer function

export const {
  addUser,
  selectBu,
  setMarketData,
  setProductData,
  approveTable,
  resetValue,
} = userSlice.actions;

export const selectUserData = (state) => state.user.data;

export default userSlice.reducer;
