import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import Roles from "./utils/Roles";
import RequireAuthorized from "./Components/common/RequireAuthorized";

import { loginRequest } from "./authConfig";

import { protectedRoutes } from "./Routes/ProtectedRoutes";
import { unprotectedRoutes } from "./Routes/UnprotectedRoutes";
import store from "./redux/store";

export default function AppRoutes() {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    const Tokengen = async () => {
      try {
        const resp = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        // filter list of groups based on if they contain the string 'orca'
        const groups = resp.idTokenClaims.groups.filter((group) =>
          group.toLowerCase().includes("orca")
        );

        // for each group see if there is a role with that key and set the user role
        let role = null;
        for (let group in groups) {
          if (Roles[groups[group]] !== undefined) {
            role = Roles[groups[group]];
            break
          }
        }

       store.dispatch({
          type: "user/addUser",
          payload: {
            user: resp.account.username,
            role: role,
            username: resp.account.username,
            groups: groups,
            token: resp.accessToken,
            name: resp.account.name,
          },
        });

      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          console.log("Interaction required, starting popup flow");
          instance.acquireTokenPopup(loginRequest);
        }
      }

    };
    Tokengen();
  });
  

  return (
    <>
      {activeAccount ? (
        <Routes>
          {
            protectedRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                  <RequireAuthorized permissions={route.permissions} path={route.path} >
                    {route.element}
                  </RequireAuthorized>
                  }
                  permissions={route.permissions}
                />
              );
            })
          }
        </Routes>
      ) : (
        <Routes>
          {
            unprotectedRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              );
            })
          }
        </Routes>
      )}
    </>
  );
}
