/* eslint-disable react/prop-types */
import React from "react";
import { LdsCard } from "@elilillyco/ux-lds-react";

const LdsCardComponent = (props) => {
  return (
    <div
      className={`row ${props.className ? props.className : ""}`}
      style={{ width: "100%" }}
    >
      <div className="col">
        <LdsCard>
          <LdsCard.Body
            className={`${props.bodyClass ? props.bodyClass : ""}`}
            style={props.bodyStyle}
          >
            {props.children}
          </LdsCard.Body>
        </LdsCard>
      </div>
    </div>
  );
};

export default LdsCardComponent;
