import React from 'react'

export const Logo = () => {
  return (
    //<img src="LillyLogoAnimated.svg" className='sm' style={{width: "75%"}}  />
    <div className='h2' style={{color:'var(--lds-color-primary)'}}>
      <div ><b>Lilly</b>&nbsp; | Orca</div>
    </div>
  )
}
