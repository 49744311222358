import React from "react";
import { LdsTooltip } from "@elilillyco/ux-lds-react";
import { OrcaFormLabelOverrides } from "./OrcaFormLabelOverrides";

export const OrcaTooltip = ({ name, text, ...rest }) => {
    return (
        <LdsTooltip openDelay={100} closeDelay={100} {...rest}>
            <LdsTooltip.Text>{OrcaFormLabelOverrides[name] || name}</LdsTooltip.Text>
            <LdsTooltip.Description>{text || name}</LdsTooltip.Description>
        </LdsTooltip>
    );
}
