import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NotAuthorizedPage from "../../Pages/SharedPages/NotAuthorized";
import { selectUserData } from "../../redux/slices/userSlice";
import { LdsLoadingSpinner } from "@elilillyco/ux-lds-react";


export default function RequireAuthorized( { children, permissions, path }) {
  const userData = useSelector(selectUserData);
  if (userData.token === null) {
    return (
      <div className='row justify-content-md-center'>
        <div className='col-2' style={{textAlign:'center'}}>
          <LdsLoadingSpinner style={{ alignItems:'center' }} size={70} animationSpeed={1500}/>
        </div>
      </div> );
  }
  if (permissions.length > 0 && !permissions.includes(userData.role)) {
    return <NotAuthorizedPage path={ path }/>;
  }
  return (
    children
  );
}
