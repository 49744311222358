/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import "@elilillyco/ux-lds-react/src/css/index.css";
import { defineLdsComponents } from "@elilillyco/ux-lds";
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import "./App.css";
import DashboardLayout from "./Layouts/DashboardLayout/DashboardLayout";
import AppRoutes from "./Route";
import { CustomNavigationClient } from "./utils/NavigationClient";
import store from "./redux/store";


function WrappedView() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (!activeAccount) {
      instance.loginRedirect().catch((error) => console.log(error));
    }
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <AppRoutes />
        </DashboardLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <DashboardLayout>
          <AppRoutes />
        </DashboardLayout>
      </UnauthenticatedTemplate>
    </>
  );
}

const App = ({ pca }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  useEffect(() => {
    defineLdsComponents();
  }, []);

  return (
    <>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <WrappedView />
        </Provider>
      </MsalProvider>
    </>
  );
};

export default App;
