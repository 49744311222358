import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrcaAPI } from '../../ApiServices/OrcaAPI';

const initialState = {
  tableData: [],
};

export const serviceCatalogSlice = createSlice({
  name: 'serviceCatalog',
  initialState,
  reducers: {
    setServiceCatalogData: (state, action) => {
        state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceCatalogData.pending, (state) => {
        state.tableData = [];
        state.loading = true;
      })
      .addCase(getServiceCatalogData.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = action.payload;
      })
      .addCase(getServiceCatalogData.rejected, (state) => {
        state.loading = false;
        state.tableData = [];
      });
  }
});

export const getServiceCatalogData = createAsyncThunk('requests/getServiceCatalogData', async () => {
  const response = await OrcaAPI({ uri: "/enhancement_request", method: "GET" });
  return response.data;
})

export const { setServiceCatalogData } = serviceCatalogSlice.actions;
export const selectServiceCatalogData = (state) => state.serviceCatalog.tableData;
export const getLoadingState = (state) => state.serviceCatalog.loading;

export default serviceCatalogSlice.reducer;