/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { LdsSkipToMain, LdsSideNav, LdsFooter } from "@elilillyco/ux-lds-react";
import { Link, useLocation, Outlet } from "react-router-dom";
import DashboardNavBar from "./components/DashboardNavBar";

export default function DashboardLayout({ children }) {
  const location = useLocation();
  const [sideNavSearchValue, setSideNavSearchValue] = useState("");

  const handleSearch = (value) => {
    // eslint-disable-next-line no-alert
    alert(`SideNav searched for: ${value}`);
  };

  const getRouterLink = (props, children) => <Link {...props}>{children}</Link>;

  return (
    <>
      <LdsSkipToMain></LdsSkipToMain>
      <DashboardNavBar />
      <Outlet />
      <main id="main" className="main-content-container" style={{'height':'calc(100vh - 112px)'}}>
        <div className="wrapper" >
        { children }
        </div>
      </main>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  hasSidenav: PropTypes.bool,
};
