import React from "react";
import Requests from "../Pages/Requests/Requests";
import NotFoundPage from "../Pages/SharedPages/NotFound";
import Contact from "../Pages/Contact/Contact";
import ServiceCatalog from "../Pages/ServiceCatalog/ServiceCatalog";
import SprintDates from "../Pages/SprintDates/SprintDates";
import { ConsolidatedSuggestionLibrary } from "../Pages/ConsolidateSuggestionLibrary/ConsolidatedSuggestionLibrary";
import { ConsolidatedInsightLibraryAdmin } from "../Pages/ConsolidatedInsightLibraryAdmin/ConsolidatedInsightLibraryAdmin";
import MetricsLibrary from "../Pages/MetricsLibrary/MetricsLibrary";

export const protectedRoutes = [
    {
        path: "/requests",
        element: <Requests />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/csl",
        element: <ConsolidatedSuggestionLibrary />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/",
        element: <Requests />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/servicecatalog",
        element: <ServiceCatalog />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/sprintdates",
        element: <SprintDates />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "*",
        element: <NotFoundPage />,
        permissions: []
    },
    {
        path: "/contactus",
        element: <Contact />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/cilAdmin",
        element: <ConsolidatedInsightLibraryAdmin />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/ml",
        element: <MetricsLibrary />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    }
]
